// Modules exported here will be included in bundle.js

export function showResultActionSF(resultAction, eventType, card) {
 
//Assigns the value of the eventType parameter passed in the function as the “eventType” property of the card object
 card["eventType"] = eventType;

//Sets the “isDirectAnswer” property of the card object to false
 card["isDirectAnswer"] = false;

//Posts a message to the Salesforce Agent Desktop app with the entire result card
 parent.postMessage(card, "*");


//The below code is the same as the result action function for Zendesk and Freshdesk.

//Gets the result action option text class, makes it hidden, and sets it as a variable.
  var optionText = resultAction.getElementsByClassName("optionText")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');

//Gets the result action execution message class, makes it visible, and sets it as a variable.
  var executedMessage = resultAction.getElementsByClassName("executedMessage")[0];
  executedMessage.classList.add('made-visible');

//Two seconds after the result action is executed, return the option text and execution message text to their original states. 
  setTimeout(function() { 
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    executedMessage.classList.remove('made-visible');
    }, 2000);
};
